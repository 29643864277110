<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import Loader from "vue-element-loading";

import api from "@/api";
import "vue2-datepicker/index.css";

import { MyComponent } from "@/components/MyComponent.js";
import AddBookingModal from "@/components/manager/AddBookingModal.vue";

export default {
  name: "show_bookings",
  mixins: [MyComponent],
  props: ["space"],
  components: {
    AddBookingModal,
    FullCalendar,
    Loader,
  },
  data: function () {
    return {
      canShowAddBookingModal: false,
      addBookingTimeObj: null,
      eventReloadIntervalID: null,
      exited: false,
      bookings: [],
      loading: false,
      eventSources: this.reloadEvents,
    };
  },

  methods: {
    setIsLoading(loadingState) {
      this.loading = loadingState;
    },
    exportBookings() {
      this.$store
        .dispatch("Manager/exportBookings", this.space.id)
        .then((data) => {
          window.open(`/api/exportTempFile/${data.short_name}`);
        });
    },

    eventClick(eventData) {
      const def = eventData.event._def;
      const recurrenceId = def.extendedProps.recurrence_id;
      if (recurrenceId) {
        this.$router.push({
          name: "RecurringBooking",
          params: { ID: recurrenceId },
        });
      } else {
        this.$router.push({
          name: "ScheduleItem",
          params: { ID: def.publicId },
        });
      }
    },
    dateCallback(timeObj) {
      this.addBookingTimeObj = timeObj;
      this.canShowAddBookingModal = true;
    },
    hideModal: function () {
      this.canShowAddBookingModal = false;
    },
    reloadEvents(timeObj, successCB, failureCB) {
      const start = timeObj.start;
      const end = timeObj.end;
      this.loading = true;
      api.Schedules.getBookingsBetween(this.slug, start, end)
        .then((response) => {
          this.loading = false;
          successCB(response.data);
        })
        .catch((err) => {
          failureCB(err);
        });
    },
    triggerRefetchEvents() {
      this.$refs.cal.getApi().refetchEvents();
    },
  },
  computed: {
    hasBookingJob() {
      return this.$store.state.Manager.bookingJob !== null;
    },
    slug() {
      return this.$route.params.slug;
    },
    config() {
      var minTime = "06:00:00";
      var maxTime = "23:59:00";
      let slotDuration = "1:00";
      if (this.space) {
        if (this.space.start_hour > -1) {
          minTime = this.space.start_hour + ":00";
        }
        if (this.space.end_hour > -1) {
          maxTime = this.space.end_hour + ":00";
        }
        if (this.space.allow_half_hour_bookings) {
          slotDuration = "00:30";
        }
      }
      const hiddenDays = [];
      if (this.space && this.space.closed_days_of_week) {
        const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        for (const cdow of this.space.closed_days_of_week.split(",")) {
          hiddenDays.push(daysOfWeek.indexOf(cdow));
        }
      }

      return {
        headerToolbar: {
          left: "prev,today,next",
          center: "title",
          right: "timeGridWeek,dayGridMonth",
        },
        loading: this.setIsLoading,
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin, // needed for dateClick
        ],
        hiddenDays: hiddenDays,
        events: this.reloadEvents,
        dateClick: this.dateCallback,
        initialView: "timeGridWeek",
        selectable: true,
        select: this.dateCallback,
        eventClick: this.eventClick,
        slotDuration: slotDuration,
        height: "auto",
        // firstDay: new Date().getDay(),
        // contentHeight: 600,
        // slotEventOverlap: false,
        // navLinks: true, // can click day/week names to navigate views
        // editable: false,
        slotMinTime: minTime,
        slotMaxTime: maxTime,
        allDaySlot: false,
        initialDate: new Date(),
      };
    },
  },
  watch: {
    $route() {
      this.triggerRefetchEvents();
    },
    space() {
      this.triggerRefetchEvents();
    },
  },
  beforeDestroy() {
    this.exited = true;
  },
  mounted() {
    setTimeout(this.hideWarning, 5000);
  },
};
</script>

<template>
  <div id="show_bookings">
    <div class="container" id="add_booking">
      <div class="row justify-content-center">
        <div class="col text-center">
          <button
            class="btn btn-primary"
            @click="canShowAddBookingModal = true"
          >
            Add Booking
          </button>
        </div>
        <div class="col text-center" v-if="space">
          <button
            class="btn btn-primary"
            @click="exportBookings"
            :disabled="hasBookingJob"
          >
            Export Bookings
          </button>
        </div>
      </div>
    </div>

    <div class="row">
      <div id="calendar" v-if="space">
        <Loader :active="loading" spinner="ring" color="#64B963" />
        <FullCalendar ref="cal" :options="config" />
      </div>
    </div>

    <template v-if="canShowAddBookingModal">
      <AddBookingModal
        :space="space"
        :timeObj="addBookingTimeObj"
        @hideModal="hideModal"
        @refetchEvents="triggerRefetchEvents"
      />
    </template>
  </div>
</template>

<style lang="scss" scoped>
#show_bookings {
  padding-top: 10px;
  padding-bottom: 30px;

  &,
  .form-control {
    font-size: 14px;
  }
}

#calendar {
  font-size: 14px;

  .fc-toolbar {
    font-size: 14px;
  }

  h2 {
    font-size: 20px;
  }

  .fc-day-header {
    font-size: 14px;
  }
}

#add_booking {
  margin-bottom: 10px;
  padding: 10px;
  background: #f6f6f6;
}
</style>
